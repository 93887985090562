import { JSX }			from "preact";

import { Link }			from "@v3/preact/router/Link";

import { Messages }		from "@spaengler/frontend/locale";

export type StartProps = Readonly<{
	msg:	Messages,
}>;

export const Start = ({ msg }:StartProps):JSX.Element =>
	<main class="Start page page--asym">
		<div class="page-bg--asym"></div>
		<div class="page-content page-content--asym">
			<h1>
				<span class="h1-title">{msg.siteTitle1} {msg.siteTitle2}</span>
				<span class="h1-subtitle">{msg.siteSubtitle}</span>
			</h1>
			<div class="Start-intro">
			<p>{msg.introduction}</p>
			<p class="center">
				<Link class="text-link" href="/introduction">
					{msg.introductionTitle}
				</Link>
			</p>
			</div>
			<hr class="form-separator"/>
			<div class="form-button-wrapper">
				<Link class="form-primary-button" href="/transcription/1/3">
					{msg.transcriptionTitle}
				</Link>
				<Link class="form-primary-button" href="/db">
					{msg.dbTitle}
				</Link>
			</div>
		</div>
	</main>;
