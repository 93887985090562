import { JSX }			from "preact";

import * as common		from "@spaengler/shared/CommonModel";

import { Messages }		from "@spaengler/frontend/locale";

import { Display }		from "@spaengler/frontend/pages/transcription/Display";
import { Toolbar }		from "@spaengler/frontend/pages/transcription/Toolbar";

export type TranscriptionProps = Readonly<{
	screen:		common.EditionScreen|null,
	statistics:	common.Statistics|null,
	spinner:	boolean,
	msg:		Messages,
	lang:		string,
}>;

export const Transcription = ({ screen, statistics, spinner, msg, lang }:TranscriptionProps):JSX.Element =>
	<main class="transcription page page--middle">
		<div class="page-content page-content--middle">
			<h1 class="sr-only">{msg.transcriptionTitle}</h1>
			{ screen === null &&
				<div class="loading">{msg.loading}</div>
			}
			{ screen !== null && statistics !== null &&
				<div>
					<Toolbar
						position="top"
						msg={msg}
						statistics={statistics}
						screen={screen}
					/>
					{ screen !== null &&
						<Display
							msg={msg}
							screen={screen}
							spinner={spinner}
							lang={lang}
						/>
					}
					<Toolbar
						position="bottom"
						msg={msg}
						statistics={statistics}
						screen={screen}
					/>
				</div>
			}
		</div>
	</main>;
