import { JSX }				from "preact";
import { useState, useId }	from "preact/hooks";

import { Arrays }			from "ts-base/arrays";

import { route }			from "@v3/preact/router/Router";
import { Link }				from "@v3/preact/router/Link";
import * as common			from "@spaengler/shared/CommonModel";

import { Messages }			from "@spaengler/frontend/locale";

import { NavigationLink }	from "@spaengler/frontend/components/NavigationLink";

export type ToolbarProps = Readonly<{
	position:	string,
	msg:		Messages,
	screen:		common.EditionScreen,
	statistics:	common.Statistics,
}>;

export const Toolbar = ({ position, msg, screen, statistics }:ToolbarProps):JSX.Element => {
	const [ error, setError ] = useState<boolean>(false);

	const handleFocus = (ev:JSX.TargetedEvent<HTMLInputElement, FocusEvent>):void => {
		ev.currentTarget.select();
	};

	const onBlur = ():void => {
		setError(false);
	};

	const goToPage = (ev:JSX.TargetedEvent<HTMLInputElement, InputEvent>):void => {
		// console.log("goToPage");
		const page = parseInt(ev.currentTarget.value, 10);
		const book = statistics[screen.bookNumber];
		if (page >= book.firstPage && page <= book.lastPage) {
			// actions.getPage(props.screen.bookNumber, page);
			route(`/transcription/${screen.bookNumber}/${page}`, "push");
		}
		else {
			console.log("page does not exist:", page);
			setError(true);
		}
	};

	const pageInput			= useId();

	const currentBook		= screen.bookNumber;
	const currentPage		= screen.pageNumber;
	const currentBookStat	= statistics[currentBook];

	const bookNumbers:ReadonlyArray<number>	= Arrays.sortBuiltin(Object.values(statistics).map((it:common.BookStats) => it.bookNumber));
	// console.log("bookNumbers", bookNumbers);
	const isFirstBook	= currentBook === bookNumbers[0];
	const isLastBook	= currentBook === bookNumbers[bookNumbers.length - 1];
	// console.log("isFirstBook", isFirstBook, isLastBook );
	const previousBook	= !isFirstBook	? currentBook - 1 : null;
	const nextBook		= !isLastBook	? currentBook + 1 : null;

	const isFirstPage	= currentPage === currentBookStat.firstPage;
	const isLastPage	= currentPage === currentBookStat.lastPage;
	const previousPage	= !isFirstPage	?	currentPage - 1	: null;
	const nextPage		= !isLastPage	?	currentPage + 1 : null;

	// TODO all the defined checks after the !isFooBar expressions are actually included in this same expression,
	// but typescript does not know about that. can we teach is somehow, or get rid of the flag checks?

	const getUrl = (book: number, page:number) => "/transcription/" + book + "/" + page;

	const previousBookNav	=
		!isFirstBook && previousBook !== null && previousBook !== 0
		?	getUrl(previousBook, statistics[previousBook].firstPage)
		:	null;
	const previousBookNav2	=
		!isFirstBook && previousBook !== null && previousBook !== 0
		?	getUrl(previousBook, statistics[previousBook].lastPage)
		:	null;
	const nextBookNav		=
		!isLastBook	&& nextBook !== null && nextBook !== 0
		?	getUrl(nextBook, statistics[nextBook].firstPage)
		:	null;
	const firstPageNav		=
		!isFirstPage
		?	getUrl(currentBook, currentBookStat.firstPage)
		:	null;
	const lastPageNav		=
		!isLastPage
		?	getUrl(currentBook, currentBookStat.lastPage)
		:	null;
	const previousPageNav	=
		!isFirstPage && previousPage !== null
		?	getUrl(currentBook, previousPage)
		:	null;
	const nextPageNav		=
		!isLastPage	&& nextPage !== null
		?	getUrl(currentBook, nextPage)
		:	null;

	const previousBookOrFirstPage		= previousBookNav	!== null ? previousBookNav	: firstPageNav;
	const nextBookOrLastPage			= nextBookNav		!== null ? nextBookNav		: lastPageNav;
	const previousPageOrPreviousBook	= previousPageNav	!== null ? previousPageNav	: previousBookNav2;
	const nextPageOrNextBook			= nextPageNav		!== null ? nextPageNav		: nextBookNav;

	return (
		<div class={ `transcription-toolbar transcription-toolbar--${position}` } aria-controls="aria-transcription-display">
			<div class="transcription-toolbar-left-page">
				<div class="transcription-toolbar-left-col">
					<NavigationLink
						title={msg.previousBook}
						customClass="transcription-button text-link"
						href={previousBookOrFirstPage}
					>
						<span class="fa fa-angle-double-left" aria-hidden="true"></span>
						<span class="sr-only">{msg.previousBook}</span>
					</NavigationLink>
					<NavigationLink
						title={msg.previousPage}
						customClass="transcription-button text-link"
						href={previousPageOrPreviousBook}
					>
						<span class="fa fa-angle-left" aria-hidden="true"></span>
						<span class="sr-only">{msg.previousPage}</span>
					</NavigationLink>
				</div>
				<div class="transcription-toolbar-right-col">
					<label for={pageInput} class="form-label ml">{msg.page}</label>
					<div class="form-field-wrapper ml">
						<input
							onFocus={handleFocus}
							onBlur={onBlur}
							onChange={goToPage}
							name="pagenumber"
							id={pageInput}
							type="text"
							class="form-input-text number transcription-input-page"
							value={currentPage}
						/>
						<span class="ml">{msg.of} {currentBookStat.lastPage}, {msg.book} {currentBook}</span>
						{ error &&
							<div id="transcription-input-page-error" class="form-error popup popup-bottom">
								{msg.errorPageDoesntExist}
							</div>
						}
					</div>
				</div>
			</div>
			<div class="transcription-toolbar-right-page">
				<div class="transcription-toolbar-left-col">
					<Link class="text-link" href={`/db?book=${currentBook}&page=${currentPage}`}>{msg.transcriptionLink}</Link>
				</div>
				<div class="transcription-toolbar-right-col">
					<NavigationLink
						title={msg.nextPage}
						customClass="transcription-button text-link"
						href={nextPageOrNextBook}
					>
						<span class="fa fa-angle-right" aria-hidden="true"></span>
						<span class="sr-only">{msg.nextPage}</span>
					</NavigationLink>
					<NavigationLink
						title={msg.nextBook}
						customClass="transcription-button text-link"
						href={nextBookOrLastPage}
					>
						<span class="fa fa-angle-double-right" aria-hidden="true"></span>
						<span class="sr-only">{msg.nextBook}</span>
					</NavigationLink>
				</div>
			</div>
		</div>
	);
};
