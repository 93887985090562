import * as common		from "@spaengler/shared/CommonModel";

import { Messages }	from "@spaengler/frontend/locale";

export type Model = {
	spinner:		boolean,
	lang:			string,
	msg:			Messages,
	statistics:		common.Statistics|null,
	announcement:	string|null,
	screen:			common.EditionScreen|null,
	persons:		ReadonlyArray<common.Person>|null,
	personsFilter:	string|null,
	db:				common.SearchScreen|null,
	dbOptions:		common.DbOptions,
	keywords:		common.Keywords|null,
	firstLetters:	ReadonlyArray<string>|null,
};

export const Model	= {
	initial:	(lang:"de"|"en"):Model => ({
		lang:			lang,
		msg:			Messages[lang],
		spinner:		false,
		statistics:		null,
		announcement:	"",
		screen:			null,
		persons:		null,
		personsFilter:	"",
		dbOptions:		common.DbOptions.empty,
		db:				null,
		keywords:		null,
		firstLetters:	null,
	} satisfies Model),
};

/*
export type Entry = {
	bookNumber:			number,
	pageNumber:			number,
	rowNumber:			number,
	dateCombined:		Date,
	scanName:			string,
	writer:				string,
	valueCalculable:	number,
	persons:			string,
	places:				string,
	ed:					common.EditionData,
};
*/
