import { JSX }		from "preact";

import { Messages }	from "@spaengler/frontend/locale";

export type NotFoundProps = Readonly<{
	msg:	Messages,
}>;

export const NotFound = ({ msg }:NotFoundProps):JSX.Element =>
	<main class="NotFound page page--asym">
		<div class="page-bg--asym"></div>
		<div class="page-content page-content--asym">
			<h1>{msg.notFoundText}</h1>
		</div>
	</main>;
