import { JSX } from "preact";

export const EnIntro = ():JSX.Element => (
	<div>
		<h1>Introduction</h1>

		<p>Among the holdings of the City Archive Salzburg are four account books (width 16,5 cm, height 39 to 41 cm) that had been kept by the household of the cloth and silk merchant Franz Anton Spängler for the period 1733 to 1785, that is a span of over five decades (City Archive Salzburg, Privatarchivalien 1172).</p>
		<p>Franz Anton Spängler (1705–1784) and his family lived in the center of Salzburg, at Alter Markt, and was one of the city’s wealthiest citizens, leaving a total worth of over 200,000 gulden in 1784 (Reith et al. 2015). This household included the children and stepchildren of his wives as well as the household staff and shop employees, as a rule over ten persons.</p>
		<p>The some 21,000 entries contained in these account books record the household’s everyday and unusual disbursements. They mainly pertained to nutrition and consumption of luxury items, followed by the procurement, care and repair of household effects and attire as well as the supply of basic needs such as firewood and water. The employment and compensation of the household staff and the private tutor was also noted down, medication orders as well as the honoraria of physicians, barber-surgeons and midwives. We find in addition entries about gifts and alms. Regular expenditures are intermingled with irregular disbursements. The amounts range from a few Kreuzer (e.g., tips) to larger payments, for example, when the butcher was paid 200 Gulden “on account” for a meat purchase. The highest figure (307 Gulden) in 1755 went to the goldsmith Johann Michael Mayr—presumably for jewelry—on the occasion of Franz Anton Spängler’s third wedding with Maria Theresia Traunbauer.</p>

		<h2>The history of everyday life and consumption and the Digital Humanities</h2>

		<p>Account books permit direct access to consumers and the world they lived in. They grant insight into predilections and preferences, availability, purchasing, pricing and price fluctuations. The importance of account books as sources for the history of everyday consumption has already been emphasized in the earlier literature (Bräuer 1915). Surviving sources have nevertheless hitherto guided the main focus of research to important personalities, such as Anton Tucher (Loose 1877), Voltaire (Besterman 1968), Johann Caspar Goethe (Holtzhauer 1973), Thomas Jefferson (Bear / Stanton 1997) or Robert Schumann (Nauhaus 1988). Growing interest in the history of everyday life, set within the context of developing research on consumption throughout history (Sandgruber 1982, Brewer / Porter 1993, Schmidt-Funke 2019), has led to editions or examinations of other account books (Händel / Herrmann 1992, Spiegel 1997, Kommer 1998, Kink 2007, Spalding / Spalding 2015). Some account books are available in facsimile, others have been transcribed.</p>
		<p>In a few cases the edition has been published, in other cases an analysis is available, albeit without allowing consultation of the originals. The existing analyses or editions grant important insights but are of limited use to readers, because many questions can only be answered by an interlinked perusal of the digitized source, its transcription and a related database. Digital access offers new possibilities for represention and and even usage as a teaching resource. Considering the variety of options offered by the Digital Humanities for analysis and usability, it seemed less appropriate to us to publish a transcription and its database in book form than it is to make full use of this digital potential (cf. Vogeler 2015 and Burghartz 2015)</p>

		<h2>Structure and specifics of the account books</h2>

		<p>The first book (1733 to 1750) bears the title on the front or inner cover: Hauß=Uncosten Conto Biechl – 1733 with the monogram FAS. This volume was begun and maintained by Franz Anton Spängler but his wife Maria Katharina soon began to add some entries (until her death in 1743). From 1744 on his second wife Anna Elisabeth performed most of these bookkeeping duties (until her death in 1753), and was finally succeeded in 1755 by the third wife, Maria Theresia, who entered most of the amounts until her death in 1780. There are occasional entries by the son Franz
		Joseph Christian as well. Franz Anton then continued the bookkeeping until shortly before his death in April 1784, when his son Franz Joseph Christian and his daughter Maria Franziska continued under the section Interregnum until 1785. Brief gaps appear in the account books shortly after the deaths of each of these wives. Franz Anton’s handwriting reveals the practiced, regular baroque script of a tradesman, whereas Maria Theresia’s initial scribbles look rather awkward. A <a href="#colorCode" class="text-link">color coding</a> in the transcription (in the following called the text edition) makes the different hands distinguishable and the various authors identifiable. The original spelling has been retained (see the edition guidelines) in order to preserve the linguistic originality and flavor of the dialect. Such contemporary terminology can be looked up in the subject index.</p>
		<p>The bookkeeping method or notation of the expenditures was similar in all four volumes with only a few changes. The top and bottom lines bear the forwarding entries (Transporto) only during the first few years, this is later dispensed with. In the beginning, monthly subtotals are provided, some of them added later or in pencil. A new page was mostly started at the beginning of each year and the New Year’s gifts were noted down: to the wife, children, employees, household staff, messengers and other servants, even the night watchmen. Some figures were apparently paid later and checked off. The list of New Year’s gifts is followed by the entries for the yearly appointments (e.g., to the wigmaker and barber), succeeded by the regular journal entries. Persons are only rarely named in full in these account books. Sometimes only their functions (court groom, Hofstaller) or for services (for the laundry, für die Wösch) are noted down and sometimes— for the household staff, for instance—only by given name. These full names could be reconstructed on the basis of other mentionings and other sources for completion in the database (cf. Reith et al. 2016). Further details abaout many individuals are indicated in the name index (see below).</p>

		<h2><a id="aboutDb">About the text edition and the database</a></h2>

		<p>The original sequence was retained in the <a class="text-link" href="/transcription/1/3">text edition</a> in order to document the structure, character and upkeep of these account books; that means, even crossouts (insofar as those struck words were legible) were reproduced, along with special characters and marginalia. The digitized data are displayed next to the pertinent transcribed page (Sahle 2017). </p>
		<p>The <a class="text-link" href="/db">database</a> acesses the transcrition by means of a text-only search and various selectible options. The database also permits computations, such as, monthly expenditures or disbursements for specific products or services. Entries lacking monetary quatntities and pure text entries (forwarding entries or subtotals) are not part of the database. One central function of the database is searching for keywords <a class="text-link" target="_blank" href="assets/ASO_keywords_1_2020.pdf">(see the list of designed keywords)</a> assigned to the fields "kind of payment", "kind of service/occasion/object" and "recipient".</p>
		<p>The electronic edition is supplemented by a <a class="text-link" href="/persons/A">name index</a> comprising 500 individuals specifically named in the books of account and who mostly occur more than once. Unless otherwise indicated, the city of Salzburg is the place of their origin and residence. The brackets of the beginning of the entry mark a naming into the account book for the indicated year. Where a Salzburg parish is named within parantheses (e.g., cathedral parish, St. Andrä or Salzburg-Nonntal), reference is made to the church registers of the parish involved, which are accessible via matricula online (data.matricula-online.eu/de/oesterreich/salzburg). The university student register (Redlich 1933), the residence rosters (Bürgerbücher) of the city of Salzburg, the city council proceedings, censuses, tax rolls and probate inventories were consulted in addition, along with secondary literature (such as Barth-Calmani 1992, Breitinger et al. 2009). Where no citations are given, the personal data come directly from the account books. The abbreviations used in the name index are listed below.</p>



		<h2>Edition guidelines</h2>

		<p>The original spelling has largely been retained. Some modifications were made to improve legibility. Capitalization has been conformed but the spelling is otherwise the same apart from marginal alterations (e.g., regarding s, ss and ß) according to modern conventions. The addition of punctuation and completions of shortened declinations, articles and word endings as well as consonants (indicated in the original as a double underlining) were made without comment for the sake of better fluency. The entries have been left in their original sequence even in cases of later emendation.</p>
		<p>These modest modifications seem justified because the facsimile is displayed right next to the transcription, making direct comparison possible. Proper names were rendered in the edition in the given spelling. However, in order to make searches feasible—despite divergent orthography—a uniform spelling was chosen for the name index; the alternate spellings are also indicated there. Given names and surnames—if known or gatherable from other contexts—were added within square brackets, in order to establish the substantial significance.</p>
		<p>Crossed-out passages and amounts are displayed as such but do not appear as values in the database. When whole pages have been struck in the original, this has not been specially noted because it is also visible in the facsimile.</p>
		<p>The original has no pagination: Our pagination for the transcription omits the beginning flyleaves (the flyleaf for volume I was inserted with the restored binding); and the page number within square brackets [xx] marks the beginning of a page. The blank pages in vol. III (pp. 104 f., 1767, and p. 173, 1771) were included in this pagination.</p>
		<p>The abbreviations and spellings of Sa (for Summa), pr (for per) and zus (for zusammen, altogether) were retained in the transcription, only the standard units x (Kreuzer) and f (Gulden) were substituted by xr and fl to facilitate legibility. Here, too, the facsimile can always be consulted.</p>

		<h2>Remarks about the currency and standard units </h2>

		<p>The Gulden was the arithmetical unit during the period under analysis: One Gulden corresponded to 60 Kreuzer, a Kreuzer, in turn, comprised 4 dinars (Pfennig), two Kreuzer equaled a half Batz, four Kreuzer, one Batz. Payments in ducats were marked with the abbreviation # and the converted value (c. 5 fl) was recorded. To approximate the purchasing power of these amounts, the following web calculator can be used: <a class="text-link" target="_blank" href="http://kaufkraftrechner.wohlstandsgeschichte.at/">http://kaufkraftrechner.wohlstandsgeschichte.at/</a></p>
		<p>Among the linear measures, the Salzburg Ell was often mentioned, which is 79,2 cm, whereas the Viennese Ell was somewhat shorter, measuring only 76,56 cm; the Salzburg Schuh (foot) measured 336 mm. The Salzburg pound (Pfund ʉ) contained 560 grams (Gramm), hence one Salzburg centner was 56 kilograms. The Salzburg pound contained 32 half-ounces (Loth/Lot) à 17,5 grams. One Quintel was 44 grams. One Schaff of wheat was 400 pounds at a volume of 8 Metzen; one Schaff of grain was 380 pounds. For barley one Schaff was 700 pounds; for oats, 740 pounds; and there were 16 Metzen for one Schaff.</p>
		<p>Among these volume measures, one Metzen contained 16 Maßl or four quarts (Viertel): One quart equaled 1,57 liters, one Maßl contained 0,39 liters. One Emer of wine measured 56,5 liters and contained 36 quarts (2 Kandl to a quart, 2 Maßl to a Kandl, and 2 Pfiff to a Maßl). 1 Yhrn (wine) corresponded to 62 liters or 40 quarts, one Emer of beer held 62 liters, and this was the same as 36 quarts.</p>
		<p>The Salzburg cord (Klafter) was 6 feet; firewood depended on the log length (mostly 3 or 4 feet). Charcoal and sand (as well as salt) were measured in cartloads (Fuder): One cartload corresponded to a two-horse cart (one Fuder = 32 Eimer à 56,5 l). - On currency and units in Salzburg see Zaisberger, pp. 181–183.</p>

		<h2>How to cite the account books and the database</h2>
		<p>The following form is recommended:</p>
		<p>At first mention the full citation: Account books Spängler Online  www.spaengler-haushaltsbuecher.at; thereafter abbreviated as ASO</p>
		<p>Citing a specific entry: ASO with the relevant volume indicated (noted as HB and the number I to IV) and the entry date (e.g., ASO, HB IV, 28. 12. 1781). If there is no date, "undated" follows, with the month given in parantheses (e.g., ASO, HB II, undat. (Jänner 1755)</p>
		<p>For references to one's own assessments using the database, please cite the web app: Ma own calculations on the basis of ASO</p>


		<h2>Literature </h2>

		<p>Gunda Barth-Scalmani, Der Handelsstand der Stadt Salzburg am Ende des 18. Jahrhunderts: Altständisches Bürgertum in Politik, Wirtschaft und Kultur, Ph.D. thesis, Salzburg 1992.</p>
		<p>James Bear and Lucia Stanton (ed.), Jefferson’s Memorandum Books. Accounts, with Legal Records and Miscellany, 1767–1826, 2 vols, Princeton–New Jersey 1997.</p>
		<p>Theodore Besterman (ed.), Voltaire s Household Accounts 1760–1778, edited in facsimile, New York 1968.</p>
		<p>Karl Bräuer, Studien zur Geschichte der Lebenshaltung in Frankfurt a.M. während des 17. und 18. Jahrhunderts. Auf Grund des Nachlasses von Dr. Gottlieb Schnapper-Arndt, 2 vols, Frankfurt/M. 1915.</p>
		<p>Friedrich Breitinger, Kurt Weinkamer and Gerda Dohle, Handwerker, Brauer, Wirte und Händler. Salzburgs gewerbliche Wirtschaft zur Mozartzeit, Salzburg 2009.</p>
		<p>John Brewer and Roy Porter (ed.), Consumption and the World of Goods, London 1993.</p>
		<p>Susanna Burghartz assisted by Sonia Calvi, Lukas Meili, Jonas Sagelsdorff and Georg Vogeler, Jahrrechnungen der Stadt Basel 1535 bis 1610, Basel–Graz 2015 (digital).</p>
		<p>Johann Caspar Goethe, Liber domesticus 1753–1779. Übertragen und kommentiert von Helmut Holtzhauer unter Mitarbeit von Irmgard Möller, 2 vols, Berne–Frankfurt/M. 1973.</p>
		<p>Fred Händel and Axel Herrmann (ed.), Das Hausbuch des Apothekers Michael Walburger 1562-1667. Quellenedition zur Kulturgeschichte eines bürgerlichen Hauswesens im 17. Jahrhundert, 5 vols, Hof 1992.</p>
		<p>Barbara Kink, Adelige Lebenswelt in Bayern im 18. Jahrhundert: die Tage- und Ausgabenbücher des Freiherrn Sebastian von Pemler von Hurlach und Leutstetten (1718–1772), Munich 2007.</p>
		<p>Björn R. Kommer, Lübeck 1787–1808: Die Haushaltungsbücher des Kaufmanns Jacob Behrens des Älteren, Lübeck 1989.</p>
		<p>Wilhelm Loose (ed.), Anton Tuchers Haushaltsbuch, 1507 bis 1517, Stuttgart 1877.</p>
		<p>Gerd Nauhaus, Robert Schumann, Tagebücher, vol. III: Haushaltsbücher, Part 1: 1837–1848 u. Part 2: 1847–1856, Basel 1988.</p>
		<p>Virgil Redlich, Die Matrikel der Universität Salzburg 1639-1810, Salzburg 1933.</p>
		<p>Reinhold Reith et al., Das Verlassenschaftsinventar des Salzburger Tuch- und Seidenhändlers Franz Anton Spängler von 1784. Einführung und kommentierte Edition in Verbindung mit Andreas Zechner, Luisa Pichler, Doris Hörmann, Jürgen Wöhry and Florian Angerer, Salzburg 2015.</p>
		<p>Reinhold Reith, Luisa Pichler-Baumgartner, Georg Stöger and Andreas Zechner (ed.), Haushalten und Konsumieren. Die Ausgabenbücher der Salzburger Kaufmannsfamilie Spängler von 1733 bis 1785, Salzburg 2016.</p>
		<p>Reinhold Reith und Georg Stöger, Exploring and Presenting Eighteenth-Century Private Consumption, in: Vierteljahrschrift für Sozial- und Wirtschaftsgeschichte 109 (2022), 72–86.</p>
		<p>Roman Sandgruber, Die Anfänge der Konsumgesellschaft. Konsumgüterverbrauch, Lebensstandard und Alltagskultur in Österreich im 18. und 19. Jahrhundert, Munich 1982.</p>
		<p>Julia A. Schmidt-Funke (ed.), Materielle Kultur und Konsum in der Frühen Neuzeit, Vienna-Cologne-Weimar 2019.</p>
		<p>Almut and Paul S. Spalding, The Account Books of the Reimarus Family of Hamburg, 1728–1780. Turf and Tailors, Books and Beer, 2 vols, Leiden 2015.</p>
		<p>Beate Spiegel, Adliger Alltag auf dem Land. Eine Hofmarksherrin, ihre Familie und ihre Untertanen in Tutzing um 1740, Munster 1997.</p>
		<p>Georg Vogeler, Digitale Edition von Wirtschafts- und Rechnungsbüchern, in: Gudrun Gleba and Niels Petersen (ed.), Wirtschafts- und Rechnungsbücher des Mittelalters und der Frühen Neuzeit. Formen und Methoden der Rechnungslegung: Städte, Klöster, Kaufleute, Göttingen 2015, 307–328.</p>
		<p>Friederike Zaisberger, Geschichte Salzburgs, Vienna–Munich 1998.</p>

		<h2>Abbreviations</h2>
		<dl>
			<div>
				<dt>AStS</dt>
				<dd>City Archive Salzburg</dd>
			</div>
			<div>
				<dt>BS FA</dt>
				<dd>Bankhaus Spängler, Family Archive</dd>
			</div>
			<div>
				<dt>MGSL</dt>
				<dd>Mitteilungen der Gesellschaft für Salzburger Landeskunde</dd>
			</div>
			<div>
				<dt>SLA</dt>
				<dd>State Archive Salzburg</dd>
			</div>
		</dl>
		<a id="colorCode"><h3>Legend of writers</h3></a>
		<dl>
			<div>
				<dt class="writer writer-AES">AES</dt>
				<dd>Anna Elisabeth Spängler</dd>
			</div>
			<div>
				<dt class="writer writer-FAS">FAS</dt>
				<dd>Franz Anton Spängler</dd>
			</div>
			<div>
				<dt class="writer writer-FJC">FJC</dt>
				<dd>Franz Joseph Christian Spängler</dd>
			</div>
			<div>
				<dt class="writer writer-MFS">MFS</dt>
				<dd>Maria Franziska Spängler</dd>
			</div>
			<div>
				<dt class="writer writer-MKS">MKS</dt>
				<dd>Maria Katharina Spängler</dd>
			</div>
			<div>
				<dt class="writer writer-MTS">MTS</dt>
				<dd>Maria Theresia Spängler</dd>
			</div>
		</dl>
	</div>
);
