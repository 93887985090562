import { JSX }		from "preact";
import { useState }	from "preact/hooks";

import { Link }		from "@v3/preact/router/Link";

import { Person }	from "@spaengler/shared/CommonModel";

import { Messages }	from "@spaengler/frontend/locale";
import * as actions from "@spaengler/frontend/actions";

export type DisplayProps = Readonly<{
	persons:		ReadonlyArray<Person>,
	personsFilter:	string,
	msg:			Messages,
	lang:			string,
}>;

export const Display = ({ persons, personsFilter, msg, lang }:DisplayProps):JSX.Element => {
	const [ fullsize, setFullsize ] = useState<number>(-1);

	return (
		<table class="Persons-table" id="persons-table fadeIn">
			<thead>
				<tr>
					<td class="Persons-name table-right">
						<label for="Persons-filter">{msg.filterLabel}:</label>
					</td>
					<td class="Persons-bio">
						<input
							id="Persons-filter"
							type="text"
							class="form-input-text Persons-filter"
							onInput={ev => actions.setFilter(ev.currentTarget.value)}
							value={personsFilter ?? ""}
						/>
					</td>
				</tr>
				<tr>
					<th class="Persons-table-header Persons-name table-right">
						{msg.personsInDb}
					</th>
					<th class="Persons-table-header Persons-bio">
						{msg.personsBio}
					</th>
				</tr>
			</thead>
			<tbody>
				{
					persons
					.filter(it => it.name.toLowerCase().includes(personsFilter.toLowerCase()))
					.map((it, index) =>
						<tr>
							<td class="Persons-name table-right">
								<Link class="text-link" href={`/db?persons=${it.name}`}>{it.name}</Link>
							</td>
							<td class="Persons-bio">
								{ it.image !== null && it.image !== "" &&
									<div
										className={`Persons-preview ${fullsize === index ? "Persons-preview--fullsize" : ""}`}
										onClick={ () => setFullsize(fullsize !== index ? index : -1 ) }
									>
										<img
											class="Persons-img"
											src={`/assets/p/${it.image}.jpg`}
											alt={`Portrait ${it.name}`}
										/>
										<div class="Persons-img-data">{it.imageData}</div>
									</div>
								}
								{ lang === "de" ? it.bio : it.bio_en }
								{ it.sources !== "" &&
									<p class="Persons-sources">{it.sources}</p>
								}
							</td>
						</tr>
				)}
			</tbody>
		</table>
	);
};
