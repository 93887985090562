import { JSX }				from "preact";

import { Nullable }			from "ts-base/nullable";
import { Validated }		from "ts-base/validated";

import { Router }			from "@v3/preact/router/Router";
import { Route }			from "@v3/preact/router/Route";

import * as common			from "@spaengler/shared/CommonModel";

import * as util			from "@spaengler/frontend/util";
import * as actions			from "@spaengler/frontend/actions";
import { Model }			from "@spaengler/frontend/Model";

import { Header }			from "@spaengler/frontend/components/Header";
import { Footer }			from "@spaengler/frontend/components/Footer";

import { Start }			from "@spaengler/frontend/pages/start/Start";
import { Transcription }	from "@spaengler/frontend/pages/transcription/Transcription";
import { Db }				from "@spaengler/frontend/pages/db/Db";
import { Persons }			from "@spaengler/frontend/pages/persons/Persons";
import { Impressum }		from "@spaengler/frontend/pages/impressum/Impressum";
import { Introduction }		from "@spaengler/frontend/pages/introduction/Introduction";
import { NotFound }			from "@spaengler/frontend/pages/notFound/NotFound";

export type AppProps = Readonly<{
	model:	Model,
}>;

export const App = ({ model }:AppProps):JSX.Element =>
	<div class="App">
		<div class="App-bg"></div>
		<Header	msg={model.msg}/>
		<Router
			routes={[
				Route.path("")({
					enabled:	true,
					content:	(_pathParams, _searchParams) => ({
						component:
							<Start
								msg={model.msg}
							/>,
						loader:		() => {
							util.setTitle(null, model.msg);
						},
						searcher:	null,
					}),
				}),
				Route.path("transcription", IntParam, IntParam)({
					enabled:	true,
					content:	([ book, page ], _searchParams) => ({
						component:
							<Transcription
								lang={model.lang}
								msg={model.msg}
								screen={model.screen}
								statistics={model.statistics}
								spinner={model.spinner}
							/>,
						loader:		() => {
							util.setTitle(model.msg.transcriptionTitle, model.msg);

							// TODO route had default values book 1 page 3 but now we get a "page not found" - OK?
							actions.getPage(book, page);
						},
						searcher:	null,
					}),
				}),
				Route.path("db")({
					enabled:	true,
					content:	(_pathParams, searchParams) => ({
						component:
							<Db
								lang={model.lang}
								msg={model.msg}
								keywords={model.keywords}
								dbOptions={model.dbOptions}
								db={model.db}
							/>,
						loader:		() => {
							util.setTitle(model.msg.dbTitle, model.msg);

							// load keywords on mount
							actions.getKeywords();

							const newDbOptions	= searchDbOptions(searchParams);
							if (newDbOptions !== null) {
								actions.setDbForm(newDbOptions);
								actions.getDb(newDbOptions, model.lang);
							}
							else {
								// reset form before mount
								actions.resetDbForm();
							}
						},
						searcher:	() => {
							const newDbOptions	= searchDbOptions(searchParams);
							if (newDbOptions !== null) {
								actions.setDbForm(newDbOptions);
								actions.getDb(newDbOptions, model.lang);
							}
						},
					}),
				}),
				// without a letter
				Route.path("persons")({
					enabled:	true,
					content:	(_pathParams, _searchParams) => ({
						component:
							<Persons
								lang={model.lang}
								msg={model.msg}
								persons={model.persons}
								personsFilter={model.personsFilter}
								firstLetters={model.firstLetters}
							/>,
						loader:		() => {
							util.setTitle(model.msg.personsTitle, model.msg);

							// get first letters on mount
							actions.getFirstLetters();

							actions.getPersons(null);
						},
						searcher:	null,
					}),
				}),
				// with a letter
				Route.path("persons", LetterParam)({
					enabled:	true,
					content:	([ letter ], _searchParams) => ({
						component:
							<Persons
								lang={model.lang}
								msg={model.msg}
								persons={model.persons}
								personsFilter={model.personsFilter}
								firstLetters={model.firstLetters}
							/>,
						loader:		() => {
							util.setTitle(model.msg.personsTitle, model.msg);

							// get first letters on mount
							actions.getFirstLetters();

							actions.getPersons(letter);
						},
						searcher:	null,
					}),
				}),
				Route.path("introduction")({
					enabled:	true,
					content:	(_pathParams, _searchParams) => ({
						component:
							<Introduction
								lang={model.lang}
							/>,
						loader:		() => {
							util.setTitle(model.msg.introductionTitle, model.msg);
						},
						searcher:	null,
					}),
				}),
				Route.path("impressum")({
					enabled:	true,
					content:	(_pathParams, _searchParams) => ({
						component:
							<Impressum
								lang={model.lang}
							/>,
						loader:		() => {
							util.setTitle(model.msg.imprintTitle, model.msg);
						},
						searcher:	null,
					}),
				}),
				Route.fallback("notFound")({
					enabled:	true,
					content:	(_searchParams) => ({
						component:
							<NotFound
								msg={model.msg}
							/>,
						loader:		() => {
							util.setTitle(model.msg.notFoundTitle, model.msg);
						},
						searcher:	null,
					}),
				}),
			]}
		/>
		<Footer msg={model.msg}/>
	</div>;

//-----------------------------------------------------------------------------

const searchDbOptions	= (searchParams:URLSearchParams):common.DbOptions|null	=> {
	const urlPersons1	= searchParams.get("persons");
	const urlBook1		= searchParams.get("book");
	const urlPage1		= searchParams.get("page");
	// TODO route why do we need this?
	if (
		urlBook1	=== null &&
		urlPage1	=== null &&
		urlPersons1	=== null
	)	return null;

	const getInt	= (param:string|null):number|null	=>
		Nullable.then(param)(IntParam.fromString);

	const urlPersons	= urlPersons1 !== "" ? urlPersons1 : null;
	const urlBook		= getInt(urlBook1);
	const urlPage		= getInt(urlPage1);

	const hasBookAndPage	= urlBook !== null && urlBook !== 0 && urlPage !== null;
	const hasPersons		= urlPersons !== null && urlPersons !== "";

	// TODO: allow all dbopts as url parameters
	const newDbOptions:common.DbOptions = {
		...common.DbOptions.empty,
		book:				hasBookAndPage	? urlBook		: null,
		page:				hasBookAndPage	? urlPage		: null,
		persons:			hasPersons		? urlPersons	: null,
		currentResultPage:	1,
	};

	return newDbOptions;
};

//-----------------------------------------------------------------------------

namespace IntParam {
	// TODO route use branded types for book and page?
	export const validateString	= (it:string):Validated<string, number>	=> {
		const value	= parseInt(it);
		if (!Number.isSafeInteger(value))	return Validated.invalid1("expected an integer");

		return Validated.valid(value);
	};

	export const fromString	= (it:string):number|null	=>
		Validated.toNullable(validateString(it));

	export const toString	= (it:number):string	=>
		it.toString();
}

namespace LetterParam {
	// TODO route use a branded type?
	// TODO route restrict this further?
	export const validateString	= (it:string):Validated<string, string>	=> {
		if (it.length !== 1)				return Validated.invalid1("expected a single letter");

		return Validated.valid(it);
	};

	export const fromString	= (it:string):string|null	=>
		Validated.toNullable(validateString(it));

	export const toString	= (it:string):string	=>
		it;
}
