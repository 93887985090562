import { JSX }			from "preact";

import { DeImpressum }	from "@spaengler/frontend/pages/impressum/DeImpressum";
import { EnImpressum }	from "@spaengler/frontend/pages/impressum/EnImpressum";

export type ImpressumProps = Readonly<{
	lang:	string,
}>;

export const Impressum = ({ lang }:ImpressumProps):JSX.Element =>
	<main class="Impressum page page--asym">
		<div class="page-bg--asym"></div>
		<div class="page-content page-content--asym">
			{ lang === "de" ? <DeImpressum/> : <EnImpressum/> }
		</div>
	</main>;
