export type Messages = {
	// actions
	entries:string,
	filteredBy:string,
	// header
	otherLang:string,
	otherLangCode:string,
	siteTitle1:string,
	siteTitle2:string,
	siteSubtitle: string,
	introductionTitle:string,
	transcriptionTitle:string,
	dbTitle:string,
	dbHint:string,
	personsTitle:string,
	transcriptionLink:string,
	// footer
	imprintTitle:string,
	// popup
	close:string,
	// Transcription page
	scanTitle:string,
	transcriptTitle:string,
	loading:string,
	page:string,
	of:string,
	book:string,
	previousBook:string,
	previousPage:string,
	nextBook:string,
	nextPage:string,
	errorPageDoesntExist:string,
	// db
	itemLabel:string,
	placeholderDate:string,
	date:string,
	from:string,
	to:string,
	min:string,
	max:string,
	reset:string,
	search:string,
	results:string,
	noResults: string,
	place:string,
	transcription:string,
	meta:string,
	day:string,
	month:string,
	item:string,
	gulden:string,
	kreuzer:string,
	value:string,
	writer:string,
	writerLong:string,
	writerHint:string,
	persons:string,
	places:string,
	keyword1:string,
	keyword2:string,
	recipient:string,
	// persons
	filterLabel:string,
	allPersons:string,
	personsInDb:string,
	personsBio:string,
	// introduction
	introduction:string,
	// not found
	notFoundTitle:string,
	notFoundText:string,
};

const de:Messages = {
	entries: "Einträge",
	filteredBy: "gefiltert nach",
	otherLang: "english",
	otherLangCode: "en",
	siteTitle1: "Die Ausgabenbücher",
	siteTitle2: "der Kaufmannsfamilie Spängler",
	siteSubtitle: "von 1733 bis 1785",
	introductionTitle: "Einleitung",
	transcriptionTitle: "Transkription",
	dbTitle: "Datenbank",
	dbHint: "weitere Hinweise",
	personsTitle: "Personen",
	imprintTitle: "Impressum",
	close: "schließen",
	transcriptionLink: "zur Datenbank",
	loading: "lade Daten",
	scanTitle:"Scan",
	transcriptTitle:"Transkription",
	page:"Seite",
	of:"von",
	book:"Buch",
	previousBook:"vorheriges Buch",
	previousPage:"vorherige Seite",
	nextBook:"nächstes Buch",
	nextPage:"nächste Seite",
	errorPageDoesntExist: "Diese Seite gibt es nicht",
	itemLabel: "Volltext",
	placeholderDate: "JJJJ-MM-TT",
	date: "Datum",
	from: "von",
	to: "bis",
	min: "min",
	max: "max",
	reset: "Eingaben löschen",
	search: "Suchen",
	results: "Treffern",
	noResults: "Es wurden leider keine Treffer gefunden!",
	place: "Stelle",
	transcription: "Transkription",
	meta: "Meta-Daten",
	day: "Tag",
	month: "Monat",
	item: "Ausgabe",
	gulden: "Gulden",
	kreuzer: "Kreuzer",
	value: "Wert",
	writer: "Verf.",
	writerLong: "Verfasser",
	writerHint:	"siehe Legende in der Einleitung",
	persons: "Personen",
	places: "Orte",
	keyword1: "Zahlungsart",
	keyword2: "Leistungsart/Anlass/Objekt",
	recipient: "Empfänger",
	filterLabel: "Namen filtern",
	allPersons: "alle Personen",
	personsInDb: "Datenbankeinträge",
	personsBio: "Biographie",
	introduction: "Vor mehr als 200 Jahren hat eine Salzburger Kaufmannsfamilie ihre alltäglichen Ausgaben minutiös notiert. Vier Haushaltsbücher mit mehr als 21.000 Einträgen gewähren nicht nur Einblick in den Konsum, sondern auch in das Alltagsleben und die sozialen Beziehungen der Familie. Mit dieser Applikation werden die Ausgabenbücher über eine vollständige Transkription samt digitaler Reproduktion und Datenbank erschlossen, die über vielfältige Suchfunktionen verfügt.",
	notFoundTitle: "Seite nicht gefunden",
	notFoundText: "Seite nicht gefunden",
};

const en:Messages = {
	entries: "entries",
	filteredBy: "filtered by",
	otherLang: "deutsch",
	otherLangCode: "de",
	siteTitle1: "The account books",
	siteTitle2: "of the merchant family Spängler",
	siteSubtitle: "from 1733 until 1785",
	introductionTitle: "Introduction",
	transcriptionTitle: "Transcription",
	dbTitle: "Database",
	dbHint: "see notes",
	personsTitle: "Persons",
	imprintTitle: "Imprint",
	close: "close",
	transcriptionLink: "to database",
	loading: "loading Data",
	scanTitle:"Scan",
	transcriptTitle:"Transcript",
	page:"page",
	of:"of",
	book:"book",
	previousBook:"Previous book",
	previousPage:"Previous page",
	nextBook:"Next book",
	nextPage:"Next page",
	errorPageDoesntExist: "This page does not exist",
	itemLabel: "fulltext",
	placeholderDate: "YYYY-MM-DD",
	date: "date",
	from: "from",
	to: "to",
	min: "min",
	max: "max",
	reset: "Clear form",
	search: "Search",
	results: "results",
	noResults: "Unfortunately no results were found!",
	place: "place",
	transcription: "transcription",
	meta: "meta-data",
	day: "day",
	month: "month",
	item: "item",
	gulden: "gulden",
	kreuzer: "kreuzer",
	value: "value",
	writer: "writer",
	writerLong: "writer",
	writerHint:	"see legend in the introduction",
	persons: "persons",
	places: "places",
	keyword1: "mode of payment",
	keyword2: "type of service / occasion / object",
	recipient: "recipient",
	filterLabel: "Filter names",
	allPersons: "all persons",
	personsInDb: "display in db",
	personsBio: "biography",
	introduction: "Over two centuries ago a Salzburg merchant family noted all their daily expenses. Four household account books with some 21.000 entries offer direct access to consumers and their \"world of goods\" and they permit an insight into everyday life and the social relactions and networks of the family. \n These books are now accessible as a web application that links the comprehensive text edition with a database.",
	notFoundTitle: "Page not found",
	notFoundText: "Page not found",
};

export const Messages = { en, de };
