import { JSX }				from "preact";

import { Messages }			from "@spaengler/frontend/locale";

import { NavigationLink }	from "@spaengler/frontend/components/NavigationLink";

export type ToolbarProps = Readonly<{
	msg:			Messages,
	firstLetters:	ReadonlyArray<string>,
}>;

export const Toolbar = ({ msg, firstLetters }:ToolbarProps):JSX.Element =>
	<div class="Persons-toolbar fadeIn">
		<ul class="Persons-toolbar-links">
			{
				firstLetters.map(letter =>
					<li><NavigationLink customClass="Persons-toolbar-link" href={`/persons/${letter}`}>{letter}</NavigationLink></li>
				)
			}
		</ul>
		<NavigationLink customClass="text-link" href="/persons">{msg.allPersons}</NavigationLink>
	</div>;
