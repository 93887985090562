import { JSX } from "preact";

import * as common		from "@spaengler/shared/CommonModel";

export type BioProps = Readonly<{
	person:	common.Person,
	lang:	string,
}>;

export const Bio = ({ person, lang }:BioProps):JSX.Element =>
	<div class="Bio">
		<h2 class="Bio-title">{person.name}</h2>
		{ person.image !== null && person.image !== "" &&
			<figure>
				<img src={`/assets/p/${person.image}.jpg`} alt={`Portrait von ${person.name}`}/>
				<figcaption>{person.imageData}</figcaption>
			</figure>
		}
		<p>
			{ lang === "de" ? person.bio : person.bio_en }
		</p>
		{ person.sources !== "" &&
			<p class="Bio-sources">{person.sources}</p>
		}
	</div>;
