import { JSX }				from "preact";

import { Messages }			from "@spaengler/frontend/locale";
import * as actions 		from "@spaengler/frontend/actions";

import { NavigationLink }	from "@spaengler/frontend/components/NavigationLink";

export type HeaderProps = Readonly<{
	msg:	Messages,
}>;

export const Header = ({ msg }:HeaderProps):JSX.Element =>
	<header class="Header">
		<NavigationLink customClass="Header-left" href="/">
			<img class="Header-logo" src="/img/logo-spaengler.png" alt=""/>
			<div class="Header-title">{msg.siteTitle1}<br/>{msg.siteTitle2}</div>
		</NavigationLink>
		<div class="Header-filler"></div>
		<nav class="Header-nav">
			<ul class="Header-nav-ul">
				<li><NavigationLink customClass="Header-link" href="/introduction">{msg.introductionTitle}</NavigationLink></li>
				<li><NavigationLink customClass="Header-link" href="/transcription/1/3">{msg.transcriptionTitle}</NavigationLink></li>
				<li><NavigationLink customClass="Header-link" href="/db">{msg.dbTitle}</NavigationLink></li>
				<li><NavigationLink customClass="Header-link" href="/persons/A">{msg.personsTitle}</NavigationLink></li>
			</ul>
		</nav>
		<button class="Header-language-switch" onClick={() => actions.setLang(msg.otherLangCode)}>
			{msg.otherLang
		}</button>
	</header>;
