import { JSX }			from "preact";

import * as common		from "@spaengler/shared/CommonModel";

import { Messages }		from "@spaengler/frontend/locale";

import { Toolbar }		from "@spaengler/frontend/pages/persons/Toolbar";
import { Display }		from "@spaengler/frontend/pages/persons/Display";

export type PersonsProps = Readonly<{
	persons:		ReadonlyArray<common.Person>|null,
	personsFilter:	string|null,
	firstLetters:	ReadonlyArray<string>|null,
	msg:			Messages,
	lang:			string,
}>;

export const Persons = ({ msg, lang, persons, personsFilter, firstLetters }:PersonsProps):JSX.Element =>
	<main class="Persons page page--asym">
		<div class="page-bg--asym"></div>
		<div class="page-content page-content--asym">
			<h1>{msg.personsTitle}</h1>
			{ persons === null &&
				<div>{msg.loading}</div>
			}
			{ persons !== null && firstLetters !== null &&
				<div>
					{ firstLetters !== null &&
						<Toolbar
							msg={msg}
							firstLetters={firstLetters}
						/>
					}
					{ persons !== null && personsFilter !== null &&
						<Display
							msg={msg}
							lang={lang}
							persons={persons}
							personsFilter={personsFilter}
						/>
					}
				</div>
			}
		</div>
	</main>;
