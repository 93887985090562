import { JSX, ComponentChildren }		from "preact";

import { Dom }			from "ts-base/web/dom";

import { usePathname }	from "@v3/preact/hook/usePathname";
import { Link }			from "@v3/preact/router/Link";

export type NavigationLinkProps	= Readonly<{
	customClass?:	string,
	title?:			string,
	href:			string|null,
	children:		ComponentChildren,
}>;

export const NavigationLink	= ({ customClass, title, href, children }:NavigationLinkProps):JSX.Element	=> {
	const pathname	= usePathname();

	return (
		href !== null
		?	<Link
				class={Dom.classes(
					"NavigationLink",
					"NavigationLink--enabled",
					pathname === href && "NavigationLink--active",
					customClass
				)}
				title={title}
				href={href}
			>
				{children}
			</Link>
		:	<span
				class={Dom.classes(
					"NavigationLink",
					"NavigationLink--disabled",
					customClass
				)}
				title={title}
			>
				{children}
			</span>
	);
};
