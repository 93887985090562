import { JSX }				from "preact";

import { Messages }			from "@spaengler/frontend/locale";

import { NavigationLink }	from "@spaengler/frontend/components/NavigationLink";

export type FooterProps = Readonly<{
	msg:	Messages,
}>;

export const Footer = ({ msg }:FooterProps):JSX.Element =>
	<footer class="Footer">
		<nav class="Footer-nav Footer-logos">
			<ul class="Footer-nav-ul">
				<li><a href="https://www.fwf.ac.at/"><img class="Footer-logo" alt="" src="/img/logos/fwf.svg"/><span class="sr-only">FWF</span></a></li>
				<li><a href="https://www.plus.ac.at"><img class="Footer-logo" alt="" src="/img/logos/uni-salzburg.svg"/><span class="sr-only">Universität Salzburg</span></a></li>
				<li><a href="https://www.stadt-salzburg.at/stadtarchiv"><img class="Footer-logo" alt="" src="/img/logos/stadtarchiv.svg"/><span class="sr-only">Stadtarchiv Salzburg</span></a></li>
				<li><a href="https://www.wissensstadt-salzburg.at"><img class="Footer-logo Footer-logo--wissen" alt="" src="/img/logos/wissen.svg"/><span class="sr-only">Wissenstadt Stadt Salzburg</span></a></li>
			</ul>
		</nav>
		<nav class="Footer-nav Footer-imprint">
			<ul class="Footer-nav-ul">
				<li><NavigationLink customClass="Footer-link" href="/impressum">{msg.imprintTitle}</NavigationLink></li>
			</ul>
		</nav>
	</footer>;
