export const resultsPerPage = 50;

export type PersonsReference = Readonly<{
	displayText:	string,
	targets:		ReadonlyArray<string>,
}>;

export type ParsedItem = Readonly<{
	personsReferences:	ReadonlyArray<PersonsReference>,
	fragments:			ReadonlyArray<string>,
}>;

export type Person = Readonly<{
	id:			number,
	name:		string,
	bio:		string|null,
	bio_en:		string|null,
	sources:	string|null,
	image:		string|null,
	imageData:	string|null,
}>;

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
export type Statistics = Readonly<{
	[ bookNumber:number ] : BookStats,
}>;

export type EditionScreen = Readonly<{
	scanName:	string,
	bookNumber:	number,
	pageNumber:	number,
	ed:			ReadonlyArray<EditionData>,
	personList: ReadonlyArray<Person>,
}>;

export type EditionData = Readonly<{
	writer:		string|null,
	// ed_tag, ed_monat, ed_posten
	day:		string|null,
	month:		string|null,
	item:		string|null,
	codedItem:	string|null,
	parsedItem:	ParsedItem|null,
	// ed_gulden, ed_kreuzer, ed_betrag_bruch
	gulden:		string|null,
	kreuzer:	string|null,
	rest:		string|null,
}>;

export type BookStats = Readonly<{
	bookNumber:	number,
	firstPage:	number,
	lastPage:	number,
	startDate:	string|null,
	endDate:	string|null,
}>;

export type SearchScreen = Readonly<{
	results:		number | null,
	resultPages:	number | null,
	offset:			number | null,
	dbOptions:		DbOptions,
	data:			ReadonlyArray<SearchData>,
	personList:		ReadonlyArray<Person>,
}>;

export type SearchData = Readonly<{
	scanName:			string,
	bookNumber:			number,
	pageNumber:			number,
	rowNumber:			number,
	dateCombined:		string|null,
	writer:				string|null,
	valueCalculable:	string|null,
	places:				string|null,
	persons:			string|null,
	keyword1:			string|null,
	keyword2:			string|null,
	recipient:			string|null,
	ed:					EditionData,
}>;

export type DbOptions = Readonly<{
	export:				boolean|null,
	currentResultPage:	number|null,
	book:				number|null,
	page:				number|null,
	min:				number|null,
	max:				number|null,
	startDate:			string|null,
	endDate:			string|null,
	item:				string|null,
	persons:			string|null,
	keyword1P1:			string|null,
	keyword1P2:			string|null,
	keyword2P1:			string|null,
	keyword2P2:			string|null,
	recipientP1:		string|null,
	recipientP2:		string|null,
	sortBy:				string,
	sortMode:			string,
}>;
export const DbOptions	= {
	empty:	{
		export:				null,
		currentResultPage:	null,
		book:				null,
		page:				null,
		min:				null,
		max:				null,
		startDate:			null,
		endDate:			null,
		item:				null,
		persons:			null,
		keyword1P1:			null,
		keyword1P2:			null,
		keyword2P1:			null,
		keyword2P2:			null,
		recipientP1:		null,
		recipientP2:		null,
		sortBy:				"row_number",
		sortMode:			"asc",
	} satisfies DbOptions,
};

export type KeywordSplit = Readonly<{
	name:		string,
	children:	ReadonlyArray<string>,
}>;

export type KeywordsSelected = Readonly<{
	keyword1:	KeywordSelected,
	keyword2:	KeywordSelected,
}>;

export type KeywordSelected = Readonly<{
	parent:	KeywordSplit|null,
	child:	string|null,
}>;

export type Keywords = Readonly<{
	keyword1:		ReadonlyArray<KeywordSplit>,
	keyword2:		ReadonlyArray<KeywordSplit>,
	recipient:		ReadonlyArray<KeywordSplit>,
	keyword1_en:	ReadonlyArray<KeywordSplit>,
	keyword2_en:	ReadonlyArray<KeywordSplit>,
	recipient_en:	ReadonlyArray<KeywordSplit>,
}>;

export type KeywordsSQL = Readonly<{
	keyword1:		ReadonlyArray<string>,
	keyword2:		ReadonlyArray<string>,
	keyword3:		ReadonlyArray<string>,
	keyword4:		ReadonlyArray<string>,
	recipient1:		ReadonlyArray<string>,
	recipient2:		ReadonlyArray<string>,
	keyword1_en:	ReadonlyArray<string>,
	keyword2_en:	ReadonlyArray<string>,
	keyword3_en:	ReadonlyArray<string>,
	keyword4_en:	ReadonlyArray<string>,
	recipient1_en:	ReadonlyArray<string>,
	recipient2_en:	ReadonlyArray<string>,
}>;
