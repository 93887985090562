import * as common			from "@spaengler/shared/CommonModel";

import { getQueryString }	from "@spaengler/frontend/util";
import { Messages }			from "@spaengler/frontend/locale";
import { Upd }				from "@spaengler/frontend/connect";
import { Model }			from "@spaengler/frontend/Model";

// hack: provided someone called connectRefresh before refresh is actually used, this cannot fail
// forget to call connectRefresh and your're in deep shit. you'll notice quite early, though.
let refresh:Upd<Model>	= null as unknown as Upd<Model>;
export const connectRefresh	= (it:Upd<Model>):void => { refresh = it; };

//------------------------------------------------------

export const setLang	= (lang:string):void => {
	// TODO strict check this
	if (lang !== "de" && lang !== "en")	throw new Error(`unexpected language ${lang}`);

	document.querySelector("html")?.setAttribute("lang", lang);

	removeDbFormVal("keyword1");
	removeDbFormVal("keyword2");

	// console.log("lang", lang);
	refresh(model => ({
		...model,
		lang:	lang,
		msg:	Messages[lang],
	}));
};

export const setDbForm	= (dbOptions:common.DbOptions):void => {
	// console.log("setDbForm", dbOptions);
	refresh(model => ({
		...model,
		dbOptions:	dbOptions,
	}));
};

export const changeCurrentResultPageData	= (currentResultPage: number) => (self:common.DbOptions):common.DbOptions	=> ({
	...self,
	currentResultPage: currentResultPage,
});

export const changeSort	= (sortBy:string) => (self:common.DbOptions):common.DbOptions	=> {
	// when switching the column, the order has to be reset to default
	const sortMode =
		sortBy !== self.sortBy ? "asc" :
		self.sortMode === "asc" ? "desc" : "asc";

	return {
		...self,
		sortBy,
		sortMode,
	};
};

export const setDbFormVal	= (path:string, value:string):void => {
	// console.log("setDbFormVal", path, value);
	refresh(model => ({
		...model,
		dbOptions: {
			...model.dbOptions,
			[path]:	value,
		},
	}));
};

export const removeDbFormVal = (path:string):void => {
	// console.log("removeDbFormVal", path);
	refresh(model => ({
		...model,
		dbOptions:	remove(model.dbOptions, path),
	}));
};

export const remove = (object:common.DbOptions, path:string):common.DbOptions => ({ ...object, [path]: null });

export const resetDbForm	= ():void => {
	// console.log("resetDbForm");
	refresh(model => ({
		...model,
		dbOptions:	common.DbOptions.empty,
		db:			null,
	}));
};

export const getDb	= (dbOptions:common.DbOptions, lang:string):void => {
	const query		= getQueryString(dbOptions);

	// console.log("query", query);
	// refresh(model => model.spinner = true);

	void fetch(`/api/db?${query}&lang=${lang}`)
	.then(res => res.json())
	.then(json =>
		refresh(model => ({
			...model,
			db:	json,
		}))
	);
};

export const getKeywords	= ():void => {
	void fetch("/api/keywords")
	.then(res => res.json())
	.then(json =>
		refresh(model => ({
			...model,
			keywords:	json,
		}))
	);
};

export const getFirstLetters	= ():void => {
	void fetch("/api/firstLetters")
	.then(res => res.json())
	.then(json =>
		refresh(model => ({
			...model,
			firstLetters:	json,
		}))
	);
};

export const getStats	= ():void => {
	// console.log("getStats");
	// refresh(model => model.spinner = true);
	void fetch("/api/statistics")
	.then(res => res.json())
	.then(json =>
		refresh(model => ({
			...model,
			statistics:	json,
		}))
	);
};

export const getPage	= (bookNumber:number, pageNumber:number):void => {
	// console.log("getPage", bookNumber,pageNumber);
	refresh(model => ({
		...model,
		spinner:	true,
	}));

	void fetch(`/api/page/${bookNumber}/${pageNumber}`)
	.then(res => res.json())
	.then((json:common.EditionScreen) =>
		refresh(model => ({
			...model,
			spinner:		false,
			screen:			json,
			announcement:	`${model.msg.transcriptionTitle} ${model.msg.page} ${json.pageNumber} in ${model.msg.book} ${json.bookNumber}`,
		}))
	);
};

export const getPersons	= (letter:string|null):void => {
	const url =
		letter !== null && letter !== ""
		?	`/api/persons/${letter}`
		:	"/api/persons/";

	// refresh(model => model.spinner = true);
	const letterOutput =
		letter !== null && letter !== ""
		?	letter
		:	"";

	void fetch(url)
	.then(res => res.json())
	.then((json:ReadonlyArray<common.Person>) =>
		refresh(model => ({
			...model,
			persons:		json,
			announcement:	`${model.msg.personsTitle} ${letterOutput}: ${json.length} ${model.msg.entries}`,
		}))
	);
};

export const setFilter = (filter:string):void => {
	refresh(model => ({
		...model,
		personsFilter:	filter,
		announcement:	`${model.msg.personsTitle} ${model.msg.filteredBy} ${filter}`,
	}));
};

/*
const announce = (message):void => {
	refresh(model => ({
		...model,
		announcement: message
	}))
};
*/

// initial action, called right after startup
export const boot = ():void => {
	getStats();
};
