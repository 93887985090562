import { JSX }			from "preact";

import * as common		from "@spaengler/shared/CommonModel";

import { TogglePopup }	from "@spaengler/frontend/components/TogglePopup";

export type PopupBioProps = Readonly<{
	personList:	ReadonlyArray<common.Person>,
	parsedItem:	common.ParsedItem,
	lang:		string,
}>;

export const PopupBio = ({ personList, parsedItem, lang }:PopupBioProps):JSX.Element => {
	const displayTexte								= parsedItem.personsReferences.map(x => x.displayText);
	const namesInPersonList:ReadonlyArray<string>	= personList.map(person => person.name);

	return (
		<div class="PopupBio">
			<div class="PopupBio-rowtext">
				{
					parsedItem.fragments.map(fragment => {
						const fragmentIndex = displayTexte.indexOf(fragment);
						if (fragmentIndex > -1) {
							const personsReference = parsedItem.personsReferences[fragmentIndex];

							// check if all targets occur in person list
							const missingPersons:ReadonlyArray<string> = personsReference.targets.filter(target => !namesInPersonList.includes(target));
							if (missingPersons.length > 0) {
								console.log(`PLEASE FIX: "${missingPersons.join("; ")}" missing in person list`);
								return <span>{fragment}</span>;
							}

							return <TogglePopup personsReference={personsReference} personList={personList} lang={lang}/>;
						}
						else {
							return <span>{fragment}</span>;
						}
					})
				}
			</div>
		</div>
	);
};
