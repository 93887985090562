import { JSX }				from "preact";
import { useId }			from "preact/hooks";

import { Arrays }			from "ts-base/arrays";

import * as common			from "@spaengler/shared/CommonModel";

import { getQueryString }	from "@spaengler/frontend/util";
import { Messages }			from "@spaengler/frontend/locale";
import * as actions			from "@spaengler/frontend/actions";

export type SearchProps = Readonly<{
	lang:		string,
	msg:		Messages,
	keywords:	common.Keywords,
	dbOptions:	common.DbOptions,
}>;

export const Search = ({ lang, msg, keywords, dbOptions }:SearchProps):JSX.Element => {
	const handleChange = (ev:JSX.TargetedEvent<HTMLInputElement, Event>):void => {
		// console.log("handleChange", ev.currentTarget.name, ev.currentTarget.value);
		actions.setDbFormVal(ev.currentTarget.name, ev.currentTarget.value);
		// console.debug("dbState", props.model);
	};

	const getKeywords = (name:string):ReadonlyArray<common.KeywordSplit> => {
		if (keywords === null)	return [];

		return (
			name === "keyword1"	?
				(	lang === "en"
					?	keywords.keyword1_en
					:	keywords.keyword1
				)
				:
			name === "keyword2"	?
				(	lang === "en"
					?	keywords.keyword2_en
					:	keywords.keyword2
				)
				:
			name === "recipient"	?
				(	lang === "en"
					?	keywords.recipient_en
					:	keywords.recipient
				)
			// else
			:	[]
		);
	};

	const showSubSelect = (name:string, p2:string|null):boolean => {
		if (p2 === null || p2 === "")	return true;

		const keywordSplits = getKeywords(name);
		const selection:common.KeywordSplit|null =
			keywordSplits.find(it => it.name === p2) ?? null;

		return (
			selection === null || selection.name === ""	?
				true
				:
			selection.children === null || selection.children.length === 0 ?
				false
				:
			// else
				true
		);
	};

	const handleSelectP1 = (ev:JSX.TargetedEvent<HTMLSelectElement, Event>, name:string):void => {
		const p1 = ev.currentTarget.value;
		actions.setDbFormVal(`${name}P1`, p1);
		if (p1 === "") {
			actions.setDbFormVal(`${name}P2`, "");
		}
	};

	const handleSelectP2 = (ev:JSX.TargetedEvent<HTMLSelectElement, Event>, name:string):void => {
		const p2 = ev.currentTarget.value;
		actions.setDbFormVal(`${name}P2`, p2);
	};

	const createP1Items = (name:string, p2:string|null):ReadonlyArray<JSX.Element> => {
		const keywordSplits = getKeywords(name);
		if (p2 === null || p2 === "") {
			return keywordSplits.map(it =>
				<option value={it.name}>{it.name}</option>
			);
		}

		const parents:ReadonlyArray<common.KeywordSplit> =
			keywordSplits.filter(it => it.children.includes(p2));
		return parents.map(it =>
			<option value={it.name}>{it.name}</option>
		);
	};

	const createP2Items = (name:string, p1:string|null):ReadonlyArray<JSX.Element> => {
		const keywordSplits = getKeywords(name);
		const selection:common.KeywordSplit|null =
			keywordSplits.find(it => it.name === p1) ?? null;

		if (p1 === null || p1 === "" || selection === null) {
			const children:ReadonlyArray<string>		= keywordSplits.flatMap(it => it.children);
			const uniqueChildren:ReadonlyArray<string>	= Arrays.distinctBuiltin(children);
			const sortedChildren:ReadonlyArray<string>	= Arrays.sortBuiltin(uniqueChildren);
			return sortedChildren.map(it =>
				<option value={it}>{it}</option>
			);
		}

		return selection.children.map(it =>
			<option value={it}>{it}</option>
		);
	};

	const reset = ():void => {
		// console.debug("reset");
		actions.resetDbForm();
	};

	const getDownloadLink = (dbOptions:common.DbOptions):string => {
		const query = getQueryString(dbOptions);
		return `/api/db?${query}&download=true&lang=${lang}`;
	};

	const handleSubmit = (ev:JSX.TargetedEvent<HTMLFormElement, Event>):void => {
		ev.preventDefault();
		// console.debug("submit", data, ev);
		actions.getDb(dbOptions, lang);
	};

	const itemId		= useId();
	const keyword1P1Id	= useId();
	const keyword1P2Id	= useId();
	const keyword2P1Id	= useId();
	const keyword2P2Id	= useId();
	const recipientP1Id	= useId();
	const recipientP2Id	= useId();
	const personsId		= useId();
	const startDateId	= useId();
	const endDateId		= useId();
	const minId			= useId();
	const maxId			= useId();
	const bookId		= useId();
	const pageId		= useId();

	return (
		<form onSubmit={handleSubmit} class="Search form fadeIn">
			<div class="form-unit">
				<label for={itemId} class="form-label form-unit-col1">{msg.itemLabel}</label>
				<input
					onChange={handleChange}
					value={dbOptions.item ?? ""}
					name="item"
					id={itemId}
					type="text"
					class="form-input-text form-unit-col2"
				/>
			</div>
			<div class="form-unit">
				<label for={keyword1P1Id} class="form-label form-unit-col1">{msg.keyword1}</label>
				<div class="form-unit-col2 flex">
					<select
						value={dbOptions.keyword1P1 ?? ""}
						class="form-select half"
						name="keyword1P1"
						id={keyword1P1Id}
						onChange={ev => handleSelectP1(ev, "keyword1")}
					>
						<option></option>
						{createP1Items("keyword1", dbOptions.keyword1P2)}
					</select>
					{	showSubSelect("keyword1", dbOptions.keyword1P1)
						?
							<>
								<label for={keyword1P2Id} class="sr-only">{msg.keyword1} 2</label>
								<select
									value={dbOptions.keyword1P2 ?? ""}
									class="form-select ml half"
									name="keyword1P2"
									id={keyword1P2Id}
									onChange={ev => handleSelectP2(ev, "keyword1")}
								>
									<option></option>
									{createP2Items("keyword1", dbOptions.keyword1P1)}
								</select>
							</>
						:	<span></span>
					}
				</div>
			</div>
			<div class="form-unit">
				<label for={keyword2P1Id} class="form-label form-unit-col1">{msg.keyword2}</label>
				<div class="form-unit-col2 flex">
					<select
						value={dbOptions.keyword2P1 ?? ""}
						class="form-select half"
						name="keyword2P1"
						id={keyword2P1Id}
						onChange={ev => handleSelectP1(ev, "keyword2")}
					>
						<option></option>
						{createP1Items("keyword2", dbOptions.keyword2P2)}
					</select>
					{	showSubSelect("keyword2", dbOptions.keyword2P1)
						?
							<>
								<label for={keyword2P2Id} class="sr-only">{msg.keyword2} 2</label>
								<select
									value={dbOptions.keyword2P2 ?? ""}
									class="form-select ml half"
									name="keyword2P2"
									id={keyword2P2Id}
									onChange={ev => handleSelectP2(ev, "keyword2")}
								>
									<option></option>
									{createP2Items("keyword2", dbOptions.keyword2P1)}
								</select>
							</>
						:	<span></span>
					}
				</div>
			</div>
			<div class="form-unit">
				<label for={recipientP1Id} class="form-label form-unit-col1">{msg.recipient}</label>
				<div class="form-unit-col2 flex">
					<select
						value={dbOptions.recipientP1 ?? ""}
						class="form-select half"
						name="recipientP1"
						id={recipientP1Id}
						onChange={ev => handleSelectP1(ev, "recipient")}
					>
						<option></option>
						{createP1Items("recipient", dbOptions.recipientP2)}
					</select>
					{	showSubSelect("recipient", dbOptions.recipientP1)
						?
							<>
								<label for={recipientP2Id} class="sr-only">{msg.recipient} 2</label>
								<select
									value={dbOptions.recipientP2 ?? ""}
									class="form-select ml half"
									name="recipientP2"
									id={recipientP2Id}
									onChange={ev => handleSelectP2(ev, "recipient")}
								>
									<option></option>
									{createP2Items("recipient", dbOptions.recipientP1)}
								</select>
							</>
						:	<span></span>
					}
				</div>
			</div>
			<div class="form-unit">
				<label for={personsId} class="form-label form-unit-col1">{msg.persons}</label>
				<input
					onChange={handleChange}
					value={dbOptions.persons ?? ""}
					name="persons"
					id={personsId}
					type="text"
					class="form-input-text form-unit-col2"
				/>
			</div>
			<div class="form-unit">
				<span class="form-label form-unit-col1" aria-hidden="true">{msg.date}</span>
				<div class="form-unit-col2">
					<label for={startDateId} class="form-label fw"><span class="sr-only">{msg.date}</span> {msg.from}</label>
					<input
						onChange={handleChange}
						value={dbOptions.startDate ?? ""}
						name="startDate"
						id={startDateId}
						type="text"
						placeholder={msg.placeholderDate}
						pattern="\d{4}-\d{1,2}-\d{1,2}"
						class="form-input-text date ml"
					/>

					<label for={endDateId} class="form-label ml fw"><span class="sr-only">{msg.date}</span> {msg.to}</label>
					<input
						onChange={handleChange}
						value={dbOptions.endDate ?? ""}
						name="endDate"
						id={endDateId}
						type="text"
						placeholder={msg.placeholderDate}
						pattern="\d{4}-\d{1,2}-\d{1,2}"
						class="form-input-text date ml"
					/>
				</div>
			</div>
			<div class="form-unit">
				<span class="form-label form-unit-col1" aria-hidden="true">{msg.value}</span>
				<div class="form-unit-col2">
					<label for={minId} class="form-label fw"><span class="sr-only">{msg.value}</span> {msg.min}</label>
					<input
						onChange={handleChange}
						value={dbOptions.min ?? ""}
						name="min"
						id={minId}
						type="text"
						placeholder="0.00"
						pattern="\d+(\.\d{1,2})?"
						class="form-input-text value ml"
					/>
					<label for={maxId} class="form-label ml fw">{msg.max}</label>
					<input
						onChange={handleChange}
						value={dbOptions.max ?? ""}
						name="max"
						id={maxId}
						type="text"
						placeholder="0.00"
						pattern="\d+(\.\d{1,2})?"
						class="form-input-text value ml"
					/>
				</div>
			</div>
			<div class="form-unit">
				<label for={bookId} class="form-label form-unit-col1">{msg.book}<span aria-hidden="true">/ {msg.page}</span></label>
				<div class="form-unit-col2">
					<input
						onChange={handleChange}
						value={dbOptions.book ?? ""}
						name="book"
						id={bookId}
						type="text"
						class="form-input-text number-l"
					/>
					<label for={pageId} class="form-label"><span class="sr-only">{msg.page}</span></label>
					<span aria-hidden="true"> / </span>
					<input
						onChange={handleChange}
						value={dbOptions.page ?? ""}
						name="page"
						id={pageId}
						type="text"
						class="form-input-text number-l"
					/>
				</div>
			</div>
			<div class="form-unit">
				<div class="form-unit-col1"></div>
				<div class=" form-unit-col2 form-button-spreader">
					<button onClick={reset} type="button" class="text-link">{msg.reset}</button>
					<a href={getDownloadLink(dbOptions)} class="text-link" download="Export.xlsx" target="_blank">Download</a>
					<button type="submit" class="form-button">{msg.search}</button>
				</div>
			</div>
			<hr class="form-separator"/>
		</form>
	);
};
