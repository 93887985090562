import { JSX, render } from "preact";

export type Endo<T> = (old:T) => T;
export type Upd<T> = (change:Endo<T>) => void;

export const tap = <T> (value:T, action:(value:T)=>void):T => {
	action(value);
	return value;
};

export const connect = <T>(container:HTMLElement, mkApp:(model:T)=>JSX.Element, initial:T):Upd<T> => {
	let model = initial;

	// let old = render(mkApp(model), container);
	return (modFunc) => {
		model = modFunc(model);
		// old   = render(mkApp(model), container, old);
		render(mkApp(model), container);
	};
};
