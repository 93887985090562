import { JSX }			from "preact";

import { Dom }			from "ts-base/web/dom";

import * as common		from "@spaengler/shared/CommonModel";

import { Messages }		from "@spaengler/frontend/locale";

import { PopupBio }		from "@spaengler/frontend/components/PopupBio";

export type DisplayProps = Readonly<{
	msg:		Messages,
	lang:		string,
	screen:		common.EditionScreen,
	spinner:	boolean,
}>;

export const Display = ({ msg, lang, screen, spinner }:DisplayProps):JSX.Element =>
	<div id="aria-transcription-display" class="transcription-display">
		<div class="transcription-col">
			<h2 class="">{msg.scanTitle}</h2>
			<img
				id={`transcription-page-${screen.bookNumber}-${screen.pageNumber}`}
				src={`/assets/${screen.bookNumber}/${screen.scanName}.jpg`}
				class={"transcription-scan" + (spinner ? "" : " fadeIn")}
				alt={`Scan der Seite ${screen.pageNumber} in Buch ${screen.bookNumber}`}
			/>
		</div>
		<div class="transcription-col">
			<h2 class="">{msg.transcriptTitle}</h2>
				<div class={"transcription-transcript" + (spinner ? "" : " fadeIn")}>
				{ screen.ed.map((it) =>
					<div
						class={Dom.classes(
							"transcription-transcript-row",
							it.writer !== null && it.writer !== "" && `writer-${formatWriterClass(it.writer)}`
						)}
						{
							...( it.writer !== null && it.writer !== "" && { title: formatWriterTitle(it.writer, msg) } )
						}
					>
						<div class="table-col1 table-right">{it.day}</div>
						<div class="table-col2">{it.month}</div>
						<div class="table-col3">
							{
								it.parsedItem !== null
								?	<PopupBio
										parsedItem={it.parsedItem}
										personList={screen.personList}
										lang={lang}
									/>
								:	it.item
							}
						</div>
						<div class="table-col4 table-right">{it.gulden}</div>
						<div class="table-col5 table-nw">{it.kreuzer} {it.rest}</div>
					</div>
				)}
			</div>
		</div>
	</div>;

const formatWriterClass	= (writer:string):string =>
	writer.trim().toUpperCase();

const formatWriterTitle	= (writer:string, msg:Messages):string =>
	`${msg.writerLong}: ${writer.trim().toUpperCase()} (${msg.writerHint})`;
