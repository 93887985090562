import { Upd, connect }		from "@spaengler/frontend/connect";
import * as actions			from "@spaengler/frontend/actions";
import { Model }			from "@spaengler/frontend/Model";
import { App }				from "@spaengler/frontend/App";

// console.log("lang",	navigator.language);
// console.log("langs",	navigator.languages);

const lang = navigator.language.startsWith("de") ? "de" : "en";

const initialModel = Model.initial(lang);

const refresh:Upd<Model> = connect(
	document.querySelector(".App-container") as HTMLElement,
	(model) => {
		return <App model={model}/>;
	},
	initialModel
);

actions.connectRefresh(refresh);
actions.boot();
