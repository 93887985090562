import { JSX }			from "preact";
import { useEffect }	from "preact/hooks";

import { DeIntro }		from "@spaengler/frontend/pages/introduction/DeIntro";
import { EnIntro }		from "@spaengler/frontend/pages/introduction/EnIntro";

export type IntroductionProps = Readonly<{
	lang:	string,
}>;

export const Introduction = ({ lang }:IntroductionProps):JSX.Element => {
	/*
	the Db page links to the #aboutDb header, but this does not work in a preact app:
	at the time where the browser recognizes the url change, the page content is
	not rendered yet - so the #aboutDb element does not exist and therefore the browser
	cannot scroll it into view.

	this would not work with a <Link> tag either, because the router
	only changes the window state in code which the browser ignores for this purpose.

	with a plain <a target="_blank" href="...#aboutDb"> and this code, we are good though.
	*/
	useEffect(
		() => {
			if (window.location.hash !== "#aboutDb")	return;

			const aboutDb	= document.getElementById("aboutDb");
			if (aboutDb === null)						return;

			window.setTimeout(() => {
				aboutDb.scrollIntoView();
			}, 200);
		},
		[]
	);

	return (
		<main class="Introduction page page--asym">
			<div class="page-bg--asym"></div>
			<div class="page-content page-content--asym">
				{ lang === "de" ? <DeIntro/> : <EnIntro/> }
			</div>
		</main>
	);
};
