import { JSX }			from "preact";

import * as common		from "@spaengler/shared/CommonModel";

import { Messages }		from "@spaengler/frontend/locale";

import { Search	}		from "@spaengler/frontend/pages/db/Search";
import { Results }		from "@spaengler/frontend/pages/db/Results";

export type DbProps = Readonly<{
	lang:		string,
	msg:		Messages,
	keywords:	common.Keywords|null,
	dbOptions:	common.DbOptions,
	db:			common.SearchScreen|null,
}>;

export const Db = ({ lang, msg, keywords, dbOptions, db }:DbProps):JSX.Element =>
	<main class="Db page page--full">
		<div class="page-content page-content--full">
			<h1>{msg.dbTitle}</h1>
			<div class="Db-hint">(<a class="text-link" href="/introduction#aboutDb" target="_blank">{msg.dbHint}</a>)</div>
			{	keywords			!== null &&
				keywords.keyword1	!== null &&
				keywords.keyword2	!== null &&
				<Search
					lang={lang}
					msg={msg}
					keywords={keywords}
					dbOptions={dbOptions}
				/>
			}
			{	db				!== null &&
				db.results		!== null &&
				db.resultPages	!== null &&
				db.offset		!== null &&
				<Results
					results={db.results}
					resultPages={db.resultPages}
					offset={db.offset}
					data={db.data}
					personList={db.personList}
					dbOptions={dbOptions}
					lang={lang}
					msg={msg}
				/>
			}
		</div>
	</main>;
